var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dealer-code"
  }, [_c("div", {
    staticClass: "operator-row"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$t("common.search")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.openDialog("create");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.add")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "info"
    },
    on: {
      click: _vm.excelexport
    }
  }, [_vm._v(_vm._s(_vm.$t("common.export")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      slot: "reference",
      size: "mini",
      type: "info"
    },
    on: {
      click: _vm.onDelete
    },
    slot: "reference"
  }, [_vm._v(_vm._s(_vm.$t("common.batch_delete")))])], 1), _vm._v(" "), _c("el-table", {
    ref: "multipleTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      "tooltip-effect": "dark"
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("dealer_code.code"),
      prop: "DealerCode",
      width: "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("dealer_code.no"),
      prop: "DealerNo",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            size: "small",
            placeholder: _vm.$t("dealer_code.no_placeholder")
          },
          on: {
            change: function change($event) {
              return _vm.handleEditDealerNo(scope.$index, scope.row);
            }
          },
          model: {
            value: scope.row.DealerNo,
            callback: function callback($$v) {
              _vm.$set(scope.row, "DealerNo", $$v);
            },
            expression: "scope.row.DealerNo"
          }
        })];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("dealer_code.name"),
      prop: "DealerName",
      width: "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("dealer_code.group"),
      prop: "Group",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("dealer_code.drp_2020"),
      prop: "DRP2020",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("dealer_code.region"),
      prop: "Region",
      width: "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("dealer_code.pc_name"),
      prop: "PCName",
      width: "420"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("common.operation"),
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            icon: "el-icon-edit",
            size: "small",
            type: "primary",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.updateDdpDealerCode(scope.row);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "primary",
            icon: "el-icon-delete",
            size: "mini",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.deleteDdpDealerCode(scope.row);
            }
          }
        })];
      }
    }])
  })], 1), _vm._v(" "), _c("el-pagination", {
    style: {
      float: "right",
      padding: "20px"
    },
    attrs: {
      "current-page": _vm.page,
      "page-size": _vm.pageSize,
      "page-sizes": [10, 30, 50, 100],
      total: _vm.total,
      layout: "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "current-change": _vm.handleCurrentChange,
      "size-change": _vm.handleSizeChange
    }
  }), _vm._v(" "), _c("el-dialog", {
    attrs: {
      "before-close": _vm.closeDialog,
      visible: _vm.dialogFormVisible,
      title: _vm.title
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      size: "mini",
      "label-position": "left",
      model: _vm.formData
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("dealer_code.no"),
      prop: "DealerNo"
    }
  }, [_c("el-input", {
    style: {
      width: "99%"
    },
    attrs: {
      placeholder: _vm.$t("dealer_code.no_placeholder"),
      clearable: ""
    },
    model: {
      value: _vm.formData.DealerNo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "DealerNo", $$v);
      },
      expression: "formData.DealerNo"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("dealer_code.name"),
      prop: "DealerName"
    }
  }, [_c("el-input", {
    style: {
      width: "99%"
    },
    attrs: {
      placeholder: _vm.$t("dealer_code.name_placeholder"),
      clearable: ""
    },
    model: {
      value: _vm.formData.DealerName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "DealerName", $$v);
      },
      expression: "formData.DealerName"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.enterDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.save")))]), _vm._v(" "), _c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };