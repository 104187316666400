import service from '@/utils/request';

// @Tags DdpDealerCode
// @Summary 创建DdpDealerCode
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealerCode true "创建DdpDealerCode"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /ddpDealerCode/createDdpDealerCode [post]
export var createDdpDealerCode = function createDdpDealerCode(data) {
  return service({
    url: "/ddpDealerCode/createDdpDealerCode",
    method: 'post',
    data: data
  });
};

// @Tags DdpDealerCode
// @Summary 删除DdpDealerCode
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealerCode true "删除DdpDealerCode"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /ddpDealerCode/deleteDdpDealerCode [delete]
export var deleteDdpDealerCode = function deleteDdpDealerCode(data) {
  return service({
    url: "/ddpDealerCode/deleteDdpDealerCode",
    method: 'delete',
    data: data
  });
};

// @Tags DdpDealerCode
// @Summary 删除DdpDealerCode
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除DdpDealerCode"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /ddpDealerCode/deleteDdpDealerCode [delete]
export var deleteDdpDealerCodeByIds = function deleteDdpDealerCodeByIds(data) {
  return service({
    url: "/ddpDealerCode/deleteDdpDealerCodeByIds",
    method: 'delete',
    data: data
  });
};

// @Tags DdpDealerCode
// @Summary 更新DdpDealerCode
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealerCode true "更新DdpDealerCode"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /ddpDealerCode/updateDdpDealerCode [put]
export var updateDdpDealerCode = function updateDdpDealerCode(data) {
  return service({
    url: "/ddpDealerCode/updateDdpDealerCode",
    method: 'put',
    data: data
  });
};

// @Tags DdpDealerCode
// @Summary 用id查询DdpDealerCode
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealerCode true "用id查询DdpDealerCode"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /ddpDealerCode/findDdpDealerCode [get]
export var findDdpDealerCode = function findDdpDealerCode(params) {
  return service({
    url: "/ddpDealerCode/findDdpDealerCode",
    method: 'get',
    params: params
  });
};

// @Tags DdpDealerCode
// @Summary 分页获取DdpDealerCode列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取DdpDealerCode列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /ddpDealerCode/getDdpDealerCodeList [get]
export var getDdpDealerCodeList = function getDdpDealerCodeList(params) {
  return service({
    url: "/ddpDealerCode/getDdpDealerCodeList",
    method: 'get',
    params: params
  });
};